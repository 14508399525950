import React from 'react';

const Disclaimer =  ({ siteTitle }) => {

  return (
    <section className="disclaimer">
      <div className="disclaimer-content">
          <p>Este sitio web ha sido creado y mantenido con el apoyo financiero de la Unión Europea a 
              través de la Organización Panamericana para el Desarrollo (PADF). Su contenido es 
              responsabilidad exclusiva de TeleSAN y no necesariamente refleja los puntos de vista de 
              la Unión Europea y PADF.</p>
      </div>
    </section>
  )
};

export default Disclaimer;
