import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from 'react';
import Backdrop from './BackdropSection';
import { graphql, useStaticQuery} from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage} from "gbimage-bridge"


const Header = ({ siteTitle }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);


  const {backgroundImage123} = useStaticQuery (
    graphql`
    query {
        backgroundImage123: file(relativePath: {eq: "logoTelesan.png"}) {
            childImageSharp {
              gatsbyImageData(
                width: 300, 
                quality: 50, 
                webpOptions: {quality: 70})
            }
        }
    }
  `
  )
  
  let navClasses = 'nav-links';
  let navItemClasses = 'nav-link-item';
  let burgerClass = "burger";
  let backdropClass = "backdrop";
  if (isOpen) {
    navClasses = 'nav-links nav-active';
    navItemClasses = 'nav-link-item-active';
    burgerClass = "burger toggle";
    backdropClass = "backdrop backdrop-active";

  }

  const pluginImage = getImage(backgroundImage123)

  return (
    <header>
      <Backdrop activeClass={backdropClass} click={toggle} />
      <nav className="navbar">
        <div className="nav-belt">
          <div>
            <h4>
              <Link className="logo" to="/" >
                <BgImage image ={pluginImage} className="logo-image">
                </BgImage>
               
              </Link>
            </h4>
          </div>
          <ul className={navClasses}>
            <Link className={navItemClasses} to="/sobrenosotros/">acerca de</Link>
            <Link className={navItemClasses} to = "/telesalud/">telesalud</Link>
            <Link className={navItemClasses} to = "/servicios/">servicios</Link>
            <Link className={navItemClasses} to = "/plataforma/">plataforma</Link>
            <Link className={navItemClasses} to = "/resultados/">resultados</Link>
            <Link className={navItemClasses} to = "/contacto/">contacto</Link>
          </ul>
          <div className={burgerClass} onClick={toggle}>
            <div className="burger-line-1"></div>
            <div className="burger-line-2"></div>
            <div className="burger-line-3"></div>
          </div>
        </div>
      </nav>
    </header>
  )
};

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header;
